package com.steamstreet.vegasful.browser.account

import com.steamstreet.aws.cognito.CognitoClient
import com.steamstreet.vegasful.browser.account.subscriptions.entitySubscribe
import com.steamstreet.vegasful.browser.account.subscriptions.myGuide
import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import org.w3c.dom.*
import org.w3c.dom.url.URLSearchParams

typealias Renderer = suspend (Element) -> Unit


val config: dynamic by lazy {
    window["vegasful"]
}

object Account {
    lateinit var cognito: CognitoClient

    private val appScope = CoroutineScope(Dispatchers.Default)

    private val modules = mapOf<String, Renderer>(
        "entity-subscribe" to ::entitySubscribe,
        "my-guide" to ::myGuide,
        "login" to { element ->
            URLSearchParams(window.location.search).let {
                val email = it.get("email")
                loginDialog(element, email)
            }
        },
        "register" to { element ->
            URLSearchParams(window.location.search).let {
                val code = it.get("confirmation-code")
                val email = it.get("confirmation-email")
                val resetPasswordEmail = it.get("reset-password-email")

                if (code != null && email != null) {
                    confirmRegistration(element, cognito, code, email)
                } else if (code != null && resetPasswordEmail != null) {
                    resetPassword(element, cognito, code, resetPasswordEmail)
                } else {
                    register(element, cognito)
                }
            }
        },
        "forgot-password" to {
            forgotPassword(it, cognito)
        }
    )

    private fun executeModule(element: Element, module: String) {
        val moduleFunction = modules[module]
        if (moduleFunction != null) {
            appScope.launch {
                moduleFunction.invoke(element)
            }
        } else {
//            logWarning("unknown.client.module.type") {
//                set("module", module)
//            }

        }
    }

    private fun render() {
        document.querySelectorAll("[data-account-module]").asList().forEach { node ->
            (node as? Element)?.let { element ->
                element.getAttribute("data-account-module")?.let {
                    executeModule(element, it)
                }
            }
        }
    }

    fun run() {
        appScope.launch {
            initAuth()
            initAccountMenu()
            render()
        }
    }

    fun navigate(url: String) {
        window.location.href = url
    }

    fun initializeBaseModules() {
        (window.get("initializeModules") as? (() -> Unit))?.invoke()
    }

    suspend fun initAuth() {
        cognito = CognitoClient(
            config["userPoolId"] as String,
            config["authClientId"] as String
        )
        GraphQL.endPoint = config["graphQlBase"] as String
        GraphQL.tokenFetcher = cognito::getAccessToken
    }

    fun login(returnUrl: String? = null) {
        if (returnUrl != null) {
            localStorage.setItem(LOGIN_REDIRECT_KEY, returnUrl)
        }
        navigate("/login")
    }

    fun getLoginReturnUrl(): String? {
        return localStorage.getItem(LOGIN_REDIRECT_KEY)
    }

    fun logout() = cognito.logout()
    fun isLoggedIn() = cognito.isLoggedIn()
}

fun onReady(cb: () -> Unit) {
    if (document.readyState === DocumentReadyState.COMPLETE || document.readyState === DocumentReadyState.INTERACTIVE) {
        cb()
    } else {
        document.addEventListener("DOMContentLoaded", {
            cb()
        })
    }
}


private const val LOGIN_REDIRECT_KEY = "vegasful_login_redirect"

fun main() {
    onReady {
        Account.run()
    }
}