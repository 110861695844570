package com.steamstreet.vegasful.css

import kotlinx.css.*
import kotlinx.css.properties.LineHeight
import kotlinx.css.properties.boxShadow

fun CssBuilder.backgroundImageUrl(url: String) {
    this.backgroundImage = Image("url($url)")
}

fun StyledElement.lineHeight(size: LinearDimension) {
    this.lineHeight = LineHeight(size.value)
}

fun CssBuilder.bottomShadow() {
    after {
        position = Position.absolute
        content = QuotedString("")
        top = 100.pct
        left = 15.px
        right = 15.px
        boxShadow(rgba(0, 0, 0, .4), 0.px, 4.px, 14.px, 6.px)
        zIndex = -1
    }
}