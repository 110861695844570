package com.steamstreet.vegasful.css

import kotlinx.css.*
import kotlinx.css.properties.Angle
import kotlinx.css.properties.LinearGradientBuilder
import kotlinx.css.properties.deg
import kotlinx.css.properties.linearGradient

object VegasfulStyles {
    val mainFont = "Montserrat"

    val maxWidth = 1200.px
    val gridWidth = 100.px
    val mainContentMaxWidth = maxWidth - (gridWidth * 2)

    val rightDuotoneImage = Image("url(/assets/ui/header-duotone-right.png)")
    val leftDuotoneImage = Image("url(/assets/ui/header-duotone-left.png)")

    val leftDuotone by css {
        position = Position.absolute
        backgroundImage = leftDuotoneImage
        width = 100.pct
        height = 200.px
        backgroundRepeat = BackgroundRepeat.noRepeat
        backgroundSize = "contain"
        zIndex = zIndexDuotones
        opacity = .1
    }

    val rightDuotone by css {
        position = Position.absolute
        backgroundImage = rightDuotoneImage
        width = 100.pct
        height = 160.px
        top = 40.px
        zIndex = zIndexDuotones
        backgroundPosition = "top right"
        backgroundRepeat = BackgroundRepeat.noRepeat
        backgroundSize = "contain"
        opacity = .1
    }

    val backgroundColor = Color.white
    val textColor = Color("#333")

    val pink = rgb(201, 0, 199)
    val lightBlue = rgb(104, 225, 255)
    val darkBlue = Color("#150136")
    val purple = Color("#494cc8")
    val red = rgb(202, 0, 69)

    val gray = rgb(230, 230, 230)
    val lightGray = Color("#f2f3f3")
    val lightestGray = Color("#fafafa")

    val gradientAngle = 150
    val pinkPurpleGradient = "linear-gradient(${gradientAngle.deg}, $pink 0%, $purple 35%, ${Color.black} 100%)"
    val darkPurpleGradient =
        "linear-gradient(${gradientAngle.deg}, $purple 0%, ${rgb(22, 15, 33)} 10%, ${Color.white} 80%)"

    val mainPageGradient = "linear-gradient(to right, rgb(41, 10, 89) 0%, rgb(92, 0, 63) 35%, black 100%)"

    val smallSubtleButton by css {
        background = darkPurpleGradient
        color = lightBlue
        fontWeight = FontWeight.bold
        padding(8.px, 15.px)
        borderWidth = 0.px
        cursor = Cursor.pointer
    }

    val standardButton by css {
        backgroundColor = Color("#494cc8")
//        background =
//            "$lightBlue linear-gradient(${gradientAngle.deg}, ${rgba(0, 0, 0, 0.0)} 0%, ${rgba(0, 0, 0, .4)} 65%)"
        color = Color("#68e1ff")
        padding(8.px, 15.px)
        borderWidth = 0.px
        cursor = Cursor.pointer
        textAlign = TextAlign.center
        display = Display.inlineBlock
        textTransform = TextTransform.uppercase
        letterSpacing = .1.em
        fontSize = 12.px
        borderRadius = 3.px
    }

    val gradientTitle by css {
        fontWeight = FontWeight.w600
        textGradient(90.deg) {
            colorStop(Color("#99ebff"))
            colorStop(Color("#ff7dfe"))
        }
    }

    val gradientTitleDark by css {
        fontWeight = FontWeight.w600
        textGradient(90.deg) {
            colorStop(Color("#494cc8"))
            colorStop(Color("#c900c7"))
        }
    }

    val largeButton by css {
        +standardButton
        fontSize = 18.px
        padding(10.px, 45.px)
        cursor = Cursor.pointer
    }

    val headerBanner by css {
        color = VegasfulStyles.pink
        fontWeight = FontWeight.w600
        fontSize = 24.px
        padding(10.px)

        whenWiderThanPortraitPhone {
            padding(0.px)
            marginBottom = 10.px
        }
    }

    val smallHeaderBanner by css {
        backgroundColor = purple
        textAlign = TextAlign.left
        padding(5.px)
        fontSize = 14.px
        textTransform = TextTransform.uppercase
        color = this@VegasfulStyles.backgroundColor
    }

    val zIndexBackground = 0
    val zIndexBelowDuotones = 30
    val zIndexDuotones = 40
    val zIndexContent = 75
    val zIndexMenu = 100

    val clearfix by css {
        after {
            content = QuotedString("")
            clear = Clear.both
            display = Display.table
        }
    }
}

fun StyledElement.textGradient(angle: Angle, block: LinearGradientBuilder.() -> Unit) {
    backgroundClip = BackgroundClip.text
    backgroundImage = linearGradient(angle) {
        block()
    }
    declarations["-webkit-background-clip"] = "text"
    declarations["-webkit-text-fill-color"] = "transparent"
}