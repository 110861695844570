package com.steamstreet.vegasful.browser.account

import com.steamstreet.aws.cognito.CognitoClient
import com.steamstreet.aws.cognito.CognitoException
import com.steamstreet.vegasful.css.css
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.html.a
import kotlinx.html.div
import kotlinx.html.input
import org.w3c.dom.Element
import org.w3c.dom.HTMLInputElement

/**
 * Render and manage the login dialog.
 */
fun loginDialog(element: Element, email: String? = null) {
    val dialog = AccountDialog(element)

    dialog.header("Login") {
        +"Not a member yet? "
        a(href = "/register") {
            +"Sign up"
        }
    }

    suspend fun submit() {
        val username = (dialog.findElementFromClassName("login-email") as? HTMLInputElement)?.value
        val password = (dialog.findElementFromClassName("login-password") as? HTMLInputElement)?.value

        if (!username.isNullOrBlank() && !password.isNullOrBlank()) {
            try {
                Account.cognito.login(username, password)
                val returnUrl = Account.getLoginReturnUrl()
                if (returnUrl == null) {
                    Account.navigate("/")
                } else {
                    Account.navigate(returnUrl)
                }
            } catch (t: CognitoException) {
                val message = when (t.code) {
                    "UserNotFoundException" -> {
                        "That email isn't registered."
                    }

                    else -> {
                        t.message ?: "Login failure"
                    }
                }
                dialog.showError(message)
            }
        }
    }

    dialog.content {
        inputField("Email") {
            input(InputType.text, classes = "login-email") {
                if (email != null) {
                    this.value = email
                }
            }
        }

        inputField("Password") {
            div {
                input(InputType.password, classes = "login-password")
            }
            div {
                css {
                    fontSize = 13.px
                    textAlign = TextAlign.right
                    marginTop = (0.5).rem
                }
                a(href = "/forgot-password") {
                    css {
                        fontWeight = FontWeight.w300
                    }
                    +"Forgot Password"
                }

            }
        }
    }

    dialog.actionButton("Login", true) {
        submit()
    }
    if (email != null) {
        dialog.focus("login-password")
    } else {
        dialog.focus("login-email")
    }
}

/**
 * Render and manage the forgot password flow.
 */
fun forgotPassword(element: Element, cognito: CognitoClient) {
    val dialog = AccountDialog(element)

    dialog.header("Forgot Password", "Forgot your password? Don't worry, we can help.")

    suspend fun submit() {
        val username = (dialog.findElementFromClassName("login-email") as? HTMLInputElement)?.value
        if (!username.isNullOrBlank()) {
            try {
                cognito.forgotPassword(username)
                dialog.content {}
                dialog.clearActions()
                dialog.header(
                    "Forgot Password",
                    "An email has been sent with information on how to reset your password."
                )
            } catch (t: CognitoException) {
                val message = when (t.code) {
                    "UserNotFoundException" -> {
                        "That email address is not registered."
                    }

                    else -> t.message ?: "An error occurred."
                }
                dialog.showError(message)
            }
        }
    }

    dialog.content {
        inputField("Email") {
            input(InputType.text, classes = "login-email")
        }
    }
    dialog.actionButton("Submit", true) {
        submit()
    }
    dialog.focus("login-email")
}

/**
 * Display the dialog to reset the password.
 */
fun resetPassword(element: Element, cognito: CognitoClient, code: String, email: String) {
    val dialog = AccountDialog(element)

    dialog.header("Reset Password", "Enter your new password")

    suspend fun submit() {
        val password = (dialog.findElementFromClassName("login-password") as? HTMLInputElement)?.value
        if (password != null) {
            try {
                cognito.resetPassword(email, code, password)

                // they are not automatically logged in... do that now.
                cognito.login(email, password)

                dialog.clearContent()
                dialog.clearActions()
                dialog.header("Reset Password", "Your password has been reset!")
                dialog.actionButton("Let's get VEGASFUL!") {
                    Account.navigate("/")
                }
            } catch (e: CognitoException) {
                dialog.showError(e.message ?: "We weren't able to update your password.")
            }
        }
    }

    dialog.content {
        inputField("Password") {
            input(InputType.password, classes = "login-password")
        }
    }

    dialog.actionButton("Submit", true) {
        submit()
    }
}