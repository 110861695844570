package com.steamstreet.vegasful.css

import kotlinx.css.CssBuilder
import kotlinx.css.Rule
import kotlinx.css.RuleContainer
import kotlinx.css.RuleSet
import kotlinx.html.CommonAttributeGroupFacade
import kotlin.reflect.KProperty

class CssDelegate<R>(val block: RuleSet) {
    operator fun getValue(thisRef: R, property: KProperty<*>): RuleSet {
        return block
    }
}

fun <R> css(block: RuleSet): CssDelegate<R> {
    return CssDelegate(block)
}

expect fun CommonAttributeGroupFacade.css(builder: CssBuilder.() -> Unit)


interface CssManager {
    fun addCss(css: CssBuilder): String

    fun writeStyleSheet(itemSelector: String, rules: RuleContainer, pw: StringBuilder) {
        val children = ArrayList<Rule>()
        val media = ArrayList<Rule>()
        rules.rules.forEach {
            if (it.selector.startsWith("&")) {
                children += it.copy(selector = itemSelector + it.selector.substring(1))
            } else if (it.selector.startsWith("@")) {
                media += it
            } else {
                children += it.copy(selector = itemSelector + " " + it.selector)
            }
        }

        rules.rules.clear()

        pw.append("$itemSelector { $rules }")

        children.forEach {
            writeStyleSheet(it.selector, CssBuilder().apply {
                it.block(this)
            }, pw)
        }

        media.forEach {
            pw.append("${it.selector} {")
            writeStyleSheet(itemSelector, CssBuilder().apply {
                it.block(this)
            }, pw)
            pw.append("}")
        }
    }
}