package com.steamstreet.vegasful.browser.account

import com.steamstreet.vegasful.css.VegasfulStyles
import com.steamstreet.vegasful.css.css
import com.steamstreet.vegasful.css.whenWiderThanPortraitPhone
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.css.*
import kotlinx.css.properties.border
import kotlinx.html.*
import kotlinx.html.dom.append
import kotlinx.html.js.onClickFunction
import kotlinx.html.js.onKeyDownFunction
import org.w3c.dom.Element
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.asList
import org.w3c.dom.events.KeyboardEvent
import org.w3c.dom.get

fun FlowContent.inputContainer(content: FlowContent.() -> Unit) {
    div(classes = "input-container") {
        content()
    }
}

fun FlowContent.inputField(label: String, content: FlowContent.() -> Unit) {
    inputContainer {
        div {
            label {
                +label
            }
            div {
                content()
            }
        }
    }
}

fun FlowContent.subHeaderContent(content: FlowContent.() -> Unit) {
    div {
        css {
            fontWeight = FontWeight.w300
        }
        content()
    }
}

fun FlowContent.accountHeader(title: String, content: FlowContent.() -> Unit) {
    header {
        css {
            textAlign = TextAlign.center
        }
        h1 {
            css {
                fontSize = (1.75).rem
                marginBottom = (0.5).rem
            }
            +title
        }
        content()
    }
}

class AccountDialog(val element: Element) {
    val scope = CoroutineScope(Dispatchers.Default)

    var submitFunction: (suspend () -> Unit)? = null

    init {
        element.append {
            div() {
                css {
                    padding(2.rem)
                    borderRadius = 3.px
                    backgroundColor = VegasfulStyles.backgroundColor
                    width = 100.pct

                    whenWiderThanPortraitPhone {
                        width = 484.px
                        border(1.px, BorderStyle.solid, VegasfulStyles.gray)
                    }

                    ".input-container" {
                        marginTop = (1.5).rem

                        "input" {
                            width = 100.pct
                            borderColor = VegasfulStyles.gray
                            borderRadius = 3.px
                            padding((0.5).rem, (0.75).rem)
                            fontWeight = FontWeight.w300

                            hover {
                                borderColor = VegasfulStyles.purple
                            }
                        }

                        "label" {
                            marginBottom = (0.25).rem
                            fontWeight = FontWeight.w700
                            display = Display.block
                        }
                    }

                    ".account-actions" {
                        marginTop = (1.5).rem
                    }
                }
                div("account-header") {}

                div(classes = "register-error") {
                    css {
                        display = Display.none
                        color = VegasfulStyles.red
                        marginTop = (1.5).rem
                        textAlign = TextAlign.center
                    }
                }

                div("account-content") {}
                div("account-actions") {}

                onKeyDownFunction = {
                    val code = (it as KeyboardEvent).keyCode
                    if (code == 13) {
                        submitFunction?.let {
                            scope.launch {
                                it.invoke()
                            }
                        }
                    }
                }
            }
        }
    }

    fun showError(text: String) {
        showError {
            +text
        }
    }

    fun showError(content: FlowContent.() -> Unit) {
        val element = element.getElementsByClassName("register-error")[0]
        element?.innerHTML = ""

        element?.append {
            div {
                content()
            }
        }
        element?.setAttribute("style", "display: block")
    }

    fun clearErrors() {
        findElementFromClassName("register-error")?.innerHTML = ""
    }

    fun findElementFromClassName(className: String): Element? {
        return element.getElementsByClassName(className).asList().firstOrNull()
    }

    private fun append(className: String, replace: Boolean = true, block: TagConsumer<*>.() -> Unit) {
        val contentEl = findElementFromClassName(className)
        if (replace) {
            contentEl?.innerHTML = ""
        }
        contentEl?.append {
            block()
        }
    }

    fun content(block: FlowContent.() -> Unit) {
        append("account-content", true) {
            div {
                block()
            }
        }
    }

    fun header(title: String, block: FlowContent.() -> Unit) {
        append("account-header", true) {
            div {
                accountHeader(title) {
                    subHeaderContent(block)
                }
            }
        }
    }

    fun header(title: String, message: String) {
        header(title) {
            +message
        }
    }

    fun focus(className: String) {
        (findElementFromClassName(className) as? HTMLInputElement)?.focus()
    }

    fun actionButton(title: String, default: Boolean = false, action: suspend () -> Unit) {
        append("account-actions", false) {
            button {
                css {
                    width = 100.pct
                    +VegasfulStyles.largeButton
                }
                this.onClickFunction = {
                    scope.launch {
                        action()
                    }
                }
                +title
            }
        }
        if (default) {
            submitFunction = action
        }
    }

    fun clearContent() {
        findElementFromClassName("account-content")?.innerHTML = ""
    }

    fun clearActions() {
        findElementFromClassName("account-actions")?.innerHTML = ""
    }
}