package com.steamstreet.vegasful.html.components

import com.steamstreet.vegasful.css.VegasfulStyles
import com.steamstreet.vegasful.css.css
import kotlinx.css.*
import kotlinx.css.properties.LineHeight
import kotlinx.html.*

fun FlowContent.mainSearchBox(title: String? = null, searchText: String? = null, placeholder: String? = null) {
    div {
        attributes["data-module"] = "global-search"
        css {
            marginTop = 10.px
        }
        if (title != null) {
            h1 {
                css {
                    fontSize = 30.px
                    textAlign = TextAlign.center
                    marginBottom = 10.px
                }
                +title
            }
        }

        div {
            css {
                position = Position.relative
            }
            input {
                css {
                    width = 100.pct
                    backgroundColor = VegasfulStyles.backgroundColor
                    borderColor = VegasfulStyles.lightBlue
                    fontSize = 24.px
                    color = VegasfulStyles.textColor
                    padding(5.px, 40.px, 5.px, 5.px)
                }
                if (placeholder != null) {
                    this.placeholder = placeholder
                }
                searchText?.let {
                    value = it
                }
            }
            span {
                css {
                    textAlign = TextAlign.center
                    display = Display.block
                    fontSize = 24.px
                    lineHeight = LineHeight("40px")
                    position = Position.absolute
                    top = 0.px
                    right = 5.px
                }
                icon("search")
            }
        }
    }
}