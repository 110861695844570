package com.steamstreet.vegasful.browser.account.subscriptions

import com.steamstreet.graphkt.client.GraphQLClientException
import com.steamstreet.vegasful.browser.account.Account
import com.steamstreet.vegasful.browser.account.GraphQL
import com.steamstreet.vegasful.css.VegasfulStyles
import com.steamstreet.vegasful.css.bottomShadow
import com.steamstreet.vegasful.css.css
import com.steamstreet.vegasful.css.whenWiderThanPortraitPhone
import com.steamstreet.vegasful.graphql.api.EventSearch
import com.steamstreet.vegasful.graphql.api.client.Event
import com.steamstreet.vegasful.graphql.api.client.Subscription
import com.steamstreet.vegasful.html.components.*
import kotlinx.browser.document
import kotlinx.css.*
import kotlinx.css.properties.TextDecoration
import kotlinx.datetime.*
import kotlinx.html.*
import kotlinx.html.dom.append
import org.w3c.dom.Element

/**
 * Render a block events list
 */
fun FlowContent.eventsList(events: List<Event>) {
    div {
        css {
            display = Display.flex
            flexDirection = FlexDirection.column
            gap = 30.px
            padding(0.px, 5.px)
            whenWiderThanPortraitPhone {
                padding(0.px)
            }
        }

        events.forEach { event ->
            div {
                css {
                    display = Display.flex
                    textDecoration = TextDecoration.none
                    gap = 10.px
                    hover {
                        textDecoration = TextDecoration.none
                        backgroundColor = Color.white.withAlpha(.1)
                    }

                    children {
                        flex(1.0)
                        firstChild {
                            flex(0.0, 0.0, 30.pct)
                        }
                    }
                }

                a {
                    css {
                        display = Display.block
                        height = 100.px
                        position = Position.relative
                        backgroundSize = "cover"
                        backgroundPosition = "center center"

                        event.images.firstOrNull()?.withWidth?.uri?.let { image ->
                            backgroundImage = Image("url($image)")
                        }

                        bottomShadow()
                    }
                    href = "/events/${event.id}"
                }

                div {
                    css {
                        padding(5.px)
                    }

                    div {
                        val start = event.date
                        val end = event.endDate

                        if (start != null && end != null) {
                            if (start == end) {
                                dateTimeDisplay(start, event.time)
                            } else {
                                if (start.year == end.year && start.year == Clock.System.now()
                                        .toLocalDateTime(TimeZone.currentSystemDefault()).year
                                ) {
                                    +"${start.monthNumber}/${start.dayOfMonth} - ${end.monthNumber}/${end.dayOfMonth}"
                                } else {
                                    +"$start - $end"
                                }
                            }
                        }
                    }

                    a {
                        css {
                            display = Display.block
                            fontWeight = FontWeight.bold
                            textDecoration = TextDecoration.none
                            color = VegasfulStyles.textColor
                            +VegasfulStyles.gradientTitleDark

                            fontSize = 24.px
                            margin(10.px, 0.px)

                        }
                        href = "/events/${event.id}"
                        +event.name
                    }

                    event.venue?.let { venue ->
                        div {
                            a(venue.path) {
                                css {
                                    color = VegasfulStyles.textColor
                                    fontWeight = FontWeight.w700
                                }
                                +venue.name
                            }
                        }
                    }
                }
            }
        }
    }
}

fun updateEventList(element: Element, events: List<Event>?) {
    val content = document.createElement("div")
    content.append {
        div {
            if (events == null) {
                notLoggedIn()
            } else if (events.isEmpty()) {
                noEvents()
            } else {
                contentTitle("Events")
                eventsList(events)
            }
        }
    }
    element.appendChild(content)
}

fun FlowContent.notLoggedIn() {
    div {
        p {
            +"""Create a Vegasful Account to follow Events, Performers, Venues and event tags to 
                                    |create a custom calendar of events!""".trimMargin()
        }

        div {
            css {
                textAlign = TextAlign.center
            }

            button {
                css {
                    +VegasfulStyles.standardButton
                    marginRight = 5.px
                }
                classes = classes + "signup-button"
                +"Sign Up or Sign In!"
            }
        }
    }
}

fun FlowContent.noEvents() {
    div {
        p {
            +"""Find and follow Events, Performers, Venues and event tags to create a custom Vegasful 
                |calendar built just for you!""".trimMargin()
        }

        div {
            mainSearchBox(placeholder = "Search for events, venues, performers, types...")
        }
    }
}

fun updateSubscriptionList(element: Element, subscriptions: List<Subscription>?) {
    val content = document.createElement("div")
    content.append {
        div {

            if (subscriptions.isNullOrEmpty()) {
            } else {
                contentTitle("Following")
                entitySimpleList(subscriptions)
            }
        }
    }
    element.appendChild(content)
}

suspend fun myGuide(element: Element) {
    element.append {
        div {
            standardHeroInfo(HeroInfoOffset.NONE) {
                this.name = "Your Event Guide"
            }

            splitContentArea {
                left {
                    div(classes = "my-guide-events") {
                        largeSpinner()
                    }
                }
                right {
                    div(classes = "my-guide-subscriptions") {
                    }
                }
            }
        }
    }


    val subscriptions = if (Account.isLoggedIn()) {
        try {
            GraphQL.client {
                subscriptions {
                    mine {
                        events(EventSearch(pageSize = 50)) {
                            events {
                                id
                                date
                                endDate
                                name
                                venue {
                                    id
                                    path
                                    name
                                }
                                images {
                                    withWidth(768) {
                                        this.uri
                                    }
                                }
                            }
                        }
                        subscriptions {
                            description
                            name
                            target {
                                this.__typename
                                this.path
                                this.images {
                                    withWidth(768) {
                                        this.uri
                                    }
                                }
                            }
                        }
                    }
                }
            }.subscriptions.mine
        } catch (e: GraphQLClientException) {
            null
        }
    } else {
        null
    }

    element.querySelector(".my-guide-events")?.let {
        it.innerHTML = ""
        updateEventList(it, subscriptions?.events?.events)
    }

    element.querySelector(".my-guide-subscriptions")?.let {
        it.innerHTML = ""
        if (subscriptions != null) {
            updateSubscriptionList(it, subscriptions.subscriptions)
        }
    }
    Account.initializeBaseModules()

    element.querySelector(".signup-button")?.let {
        it.addEventListener("click", {
            Account.login()
        })
    }
}

fun FlowContent.dateTimeDisplay(date: LocalDate?, time: LocalTime?, shortDayOnMobile: Boolean = false) {
    div {
        css {
            children {
                marginRight = 3.px
                fontWeight = FontWeight.w400

                firstChild {
                    fontWeight = FontWeight.w700
                }
            }
        }
        if (date != null) {
            span {
                css {
                    textTransform = TextTransform.uppercase
                }
                +"${date.month.name.take(3).toTitleCase()} ${date.dayOfMonth}"
            }
            span {
                if (shortDayOnMobile) {
                    css {
                        display = Display.none
                        whenWiderThanPortraitPhone {
                            display = Display.inline
                        }
                    }
                }

                +date.dayOfWeek.name.toTitleCase()
            }
            if (shortDayOnMobile) {
                span {
                    css {
                        display = Display.inline
                        whenWiderThanPortraitPhone {
                            display = Display.none
                        }
                    }
                    +date.dayOfWeek.name.substring(3).toTitleCase()
                }
            }
        }
        if (time != null) {
            span {
                if (time.hour < 12) {
                    +"${time.hour}:${time.minute.pad(2)} pm"
                } else {
                    +"${time.hour - 12}:${time.minute.pad(2)} pm"
                }
            }
        }
    }
}

fun String.toTitleCase() = lowercase().replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }

fun Int.pad(size: Int): String {
    var s = "$this"
    while (s.length < size) {
        s = "0$s"
    }
    return s
}