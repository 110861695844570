package com.steamstreet.vegasful.browser.account.subscriptions

import com.steamstreet.vegasful.css.VegasfulStyles
import com.steamstreet.vegasful.css.bottomShadow
import com.steamstreet.vegasful.css.css
import com.steamstreet.vegasful.graphql.api.client.Subscription
import kotlinx.css.*
import kotlinx.css.properties.TextDecoration
import kotlinx.html.FlowContent
import kotlinx.html.a
import kotlinx.html.div

/**
 * Display a list of entity children, usually on a page to show the children of a given entity.
 */
fun FlowContent.entitySimpleList(entities: List<Subscription>) {
    div {
        css {
            display = Display.flex
            flexDirection = FlexDirection.column
            gap = 30.px
        }
        entities.forEach { child ->
            a {
                css {
                    display = Display.flex
                    color = VegasfulStyles.purple
                    fontSize = 21.px
                    fontWeight = FontWeight.bold
                    height = 75.px
                    alignItems = Align.center

                    hover {
                        textDecoration = TextDecoration.none
                    }

                    children {
                        firstChild {
                            position = Position.relative
                            backgroundSize = "cover"
                            height = 75.px
                            backgroundPosition = "center center"
                            bottomShadow()
                            flex(0.0, 0.0, 75.px)
                        }
                    }
                }
                href = child.target.path ?: "#"

                val image = child.target.images?.firstOrNull()?.withWidth
                div {
                    css {
                        if (image != null) {
                            backgroundImage = Image("url(${image.uri})")
                        }
                    }
                }

                div {
                    css {
                        put("text-shadow", "white 2px 2px 2px")
                        fontSize = 18.px
                        paddingLeft = 10.px
                    }
                    +(child.name ?: "")
                }
            }
        }
    }
}