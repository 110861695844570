package com.steamstreet.vegasful.css

import kotlinx.css.*

object Responsive {
    val maxWidth = 1120.px

    val widthSmall = 600.px
    val widthMedium = 800.px
    val widthLarge = 1160.px

    val wideColumn = 65.pct
    val narrowColumn = 35.pct
}

fun CssBuilder.minWidth(width: LinearDimension, rules: RuleSet) = media("screen and (min-width: $width)", rules)
fun CssBuilder.maxWidth(width: LinearDimension, rules: RuleSet) = media("screen and (max-width: $width)", rules)

fun CssBuilder.portraitPhone(ruleSet: RuleSet) = maxWidth(Responsive.widthSmall.minus(1.px), ruleSet)
fun CssBuilder.whenWiderThanPortraitPhone(ruleSet: RuleSet) = minWidth(Responsive.widthSmall, ruleSet)
fun CssBuilder.WhenWiderThanTablet(ruleSet: RuleSet) = minWidth(Responsive.widthMedium, ruleSet)
fun CssBuilder.whenReallyWide(ruleSet: RuleSet) = minWidth(Responsive.widthLarge, ruleSet)