package com.steamstreet.vegasful.html.components

import com.steamstreet.vegasful.css.VegasfulStyles
import com.steamstreet.vegasful.css.VegasfulStyles.headerBanner
import com.steamstreet.vegasful.css.VegasfulStyles.smallHeaderBanner
import com.steamstreet.vegasful.css.css
import com.steamstreet.vegasful.css.whenWiderThanPortraitPhone
import kotlinx.css.*
import kotlinx.html.*

/**
 * Render a column header
 */
fun FlowContent.columnHeader(
    title: String, css: RuleSet,
    subTitle: String? = null, subTitleFontSize: LinearDimension? = null,
    titleLink: String? = null
) {
    columnHeader {
        this.css = css
        title(title, titleLink)

        if (subTitle != null) {
            subTitle(subTitle, subTitleFontSize)
        }
    }
}

class ColumnHeaderConfig {
    internal var title: String? = null
    internal var titleSize: LinearDimension? = null
    internal var subTitle: String? = null
    internal var subTitleSize: LinearDimension? = null

    internal var titleLink: String? = null

    internal var iconId: String? = null
    internal var iconLink: String? = null

    internal var css: RuleSet = headerBanner

    fun title(str: String, link: String? = null) {
        this.title = str
        this.titleLink = link
    }

    fun subTitle(str: String, fontSize: LinearDimension? = null) {
        this.subTitle = str
        subTitleSize = fontSize
    }

    fun icon(id: String, link: String? = null) {
        iconId = id
        iconLink = link
    }
}

fun FlowContent.columnHeader(text: String) {
    columnHeader {
        title(text)
    }
}

/**
 * Render a column header, configured with the provided initializer.
 */
fun FlowContent.columnHeader(block: ColumnHeaderConfig.() -> Unit) {
    val config = ColumnHeaderConfig()
    config.block()

    h3 {
        css {
            +config.css
        }
        if (config.subTitle != null) {
            div {
                css {
                    this.fontSize = config.subTitleSize ?: 13.px
                }
                +config.subTitle!!
            }
        }
        if (config.titleLink != null) {
            a(href = config.titleLink) {
                css {
                    display = Display.block
                }
                +(config.title!!)
            }
        } else {
            +(config.title!!)
        }

        if (config.iconId != null) {
            span {
                css {
                    marginLeft = 1.rem
                }
                if (config.iconLink != null) {
                    a(href = config.iconLink) {
                        icon(config.iconId!!)
                    }
                } else {
                    icon(config.iconId!!)
                }
            }
        }
    }
}

fun FlowContent.largeColumnHeader(title: String, subTitle: String? = null, titleLink: String? = null) {
    columnHeader(title, headerBanner, subTitle, 13.px, titleLink)
}

fun FlowContent.smallColumnHeader(title: String, titleLink: String? = null) {
    columnHeader(title, smallHeaderBanner, titleLink)
}

fun FlowContent.largeInfoBox(
    title: String, titleLink: String? = null, subTitle: String? = null,
    block: HtmlBlockTag.() -> Unit
) {
    div {
        largeColumnHeader(title, subTitle, titleLink)
        div {
            block()
        }
    }
}

fun FlowContent.smallInfoBox(title: String, titleLink: String? = null, block: HtmlBlockTag.() -> Unit) {
    div {
        css {
            overflow = Overflow.hidden
            "img" {
                maxWidth = 100.pct
            }
        }
        smallColumnHeader(title, titleLink)
        div {
            block()
        }
    }
}

fun FlowContent.contentTitle(title: String) {
    h3 {
        css {
            color = VegasfulStyles.pink
            fontWeight = FontWeight.w600
            fontSize = 24.px

            padding(10.px)

            whenWiderThanPortraitPhone {
                padding(0.px)
            }
        }
        +title
    }
}