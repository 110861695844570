package com.steamstreet.vegasful.browser.account

import com.steamstreet.graphkt.client.ktor.GraphQLKtorClient
import com.steamstreet.vegasful.graphql.api.client.*
import kotlinx.browser.document
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext

object GraphQL {
    var endPoint: String = "${document.location?.origin}/api/graphql"
    var tokenFetcher: (suspend () -> String?)? = null

    val graphQL by lazy {
        GraphQLKtorClient(endPoint) {
            val headers = HashMap<String, String>()
            val token = tokenFetcher?.invoke()
            headers["Authorization"] = token?.let { "Bearer $it" } ?: "GUEST"
            headers
        }
    }

    suspend fun client(block: _QueryQuery.() -> Unit): Query {
        return withContext(Dispatchers.Default) {
            graphQL.query {
                block()
            }
        }
    }

    suspend fun mutation(block: _MutationQuery.() -> Unit): Mutation {
        return withContext(Dispatchers.Default) {
            graphQL.mutation {
                block()
            }
        }
    }
}