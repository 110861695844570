package com.steamstreet.vegasful.browser.account.subscriptions

import com.steamstreet.graphkt.client.GraphQLClientException
import com.steamstreet.vegasful.browser.account.Account
import com.steamstreet.vegasful.browser.account.GraphQL
import com.steamstreet.vegasful.graphql.api.SubscriptionInput
import kotlinx.browser.document
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.launch
import kotlinx.css.*
import org.w3c.dom.Element
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.events.Event
import org.w3c.dom.events.EventTarget
import kotlin.properties.Delegates.observable

val eventHandlerContext = CoroutineScope(Dispatchers.Default)
fun EventTarget.onClick(handler: suspend (Event) -> Unit) {
    addEventListener("click", {
        eventHandlerContext.launch {
            handler(it)
        }
    })
}

class EntitySubscribeButton(private val context: CoroutineScope, val element: Element) {
    val entity = element.getAttribute("data-entity-path")

    private var isSubscribed: Boolean? by observable(null) { _, _, new ->
        context.launch {
            updateSubscriptionButton(new)
        }
    }

    private fun insertStyles() {
        val styles = CssBuilder().apply {
            ".follow-button" {
                backgroundColor = rgb(201, 0, 199)
                color = Color.white
                fontWeight = FontWeight.bold
                padding(4.px, 10.px)
                marginTop = 10.px
                cursor = Cursor.pointer
            }
        }
        val style = document.createElement("style")
        style.appendChild(document.createTextNode(styles.toString()))
        document.head?.appendChild(style)
    }

    private suspend fun updateSubscriptionButton(subscribed: Boolean?) {
        if (subscribed != null) {
            val oldButton = element.querySelector("button") as? HTMLButtonElement
            val button = oldButton?.cloneNode(true) as? HTMLButtonElement

            if (subscribed) {
                button?.innerHTML = "Unfollow"
            } else {
                button?.innerHTML = "Follow"
            }
            val handler: suspend (Event) -> Unit = if (!Account.isLoggedIn()) {
                {
                    Account.login("${entity}?subscribe=1")
                }
            } else if (subscribed) {
                { unsubscribe() }
            } else {
                { subscribe() }
            }
            button?.onClick(handler)
            button?.setAttribute("style", "visibility:visible")

            if (oldButton != null && button != null) {
                element.replaceChild(button, oldButton)
            }
        }
    }

    private suspend fun unsubscribe() {
        if (entity == null) return

        GraphQL.mutation {
            subscriptions {
                unsubscribe(SubscriptionInput(null, entity))
            }
        }.let {
            isSubscribed = false
        }
    }

    private suspend fun subscribe() {
        if (entity == null) return

        GraphQL.mutation {
            subscriptions {
                subscribe(SubscriptionInput(null, entity)) {
                    this.date
                }
            }
        }.let {
            isSubscribed = true
        }
    }

    private suspend fun checkSubscription() {
        if (entity == null) return

        if (!Account.isLoggedIn()) {
            isSubscribed = false
            return
        }

        try {
            GraphQL.client {
                subscriptions {
                    mine {
                        subscription(entity) {
                            this.date
                        }
                    }
                }
            }.let {
                val loggedIn = it.subscriptions.mine != null
                val subscription = it.subscriptions.mine?.subscription

                isSubscribed = subscription != null

                if (loggedIn && subscription == null) {
                    val shouldSubscribe = document.location?.href?.substringAfter("?")?.split("&")?.map {
                        it.split("=").let {
                            if (it.size == 1) it.first() to it.first()
                            else it[0] to it[1]
                        }
                    }?.toMap()?.get("subscribe") == "1"

                    if (shouldSubscribe) {
                        isSubscribed = true // assume the subscription is going to work
                        subscribe()
                    }
                }
            }
        } catch (ce: GraphQLClientException) {
            isSubscribed = false
        }
    }

    suspend fun run() {
        insertStyles()
        checkSubscription()
    }
}

suspend fun entitySubscribe(element: Element) = coroutineScope {
    EntitySubscribeButton(this, element).run()
}