@file:Suppress("unused", "UNUSED_CHANGED_VALUE", "PropertyName", "FunctionName", "ClassName")

package com.steamstreet.vegasful.graphql.api.client

import com.steamstreet.graphkt.GraphKtQuery
import com.steamstreet.graphkt.ID
import com.steamstreet.graphkt.client.GraphQLClient
import com.steamstreet.graphkt.client.QueryWriter
import com.steamstreet.vegasful.graphql.api.ArticleInput
import com.steamstreet.vegasful.graphql.api.ArticleSearchInput
import com.steamstreet.vegasful.graphql.api.EventInstant
import com.steamstreet.vegasful.graphql.api.EventSearch
import com.steamstreet.vegasful.graphql.api.EventUpdateInput
import com.steamstreet.vegasful.graphql.api.GlobalSearchInput
import com.steamstreet.vegasful.graphql.api.ImageUpload
import com.steamstreet.vegasful.graphql.api.LoaderConfiguration
import com.steamstreet.vegasful.graphql.api.LoaderExecutionInput
import com.steamstreet.vegasful.graphql.api.MergeInput
import com.steamstreet.vegasful.graphql.api.PerformerUpdateInput
import com.steamstreet.vegasful.graphql.api.SubscriptionInput
import com.steamstreet.vegasful.graphql.api.SystemEventInput
import com.steamstreet.vegasful.graphql.api.TagUpdateInput
import com.steamstreet.vegasful.graphql.api.VenueUpdateInput
import com.steamstreet.vegasful.graphql.api.json
import kotlin.Boolean
import kotlin.Int
import kotlin.String
import kotlin.Suppress
import kotlin.Unit

@GraphKtQuery
class _QueryQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val permissions: Unit
    get() {
      writer.println("permissions")
    }

  fun venues(block: _VenuesQuery.() -> Unit) {
    writer.print("venues")
    writer.println(" {")
    writer.indent {
      _VenuesQuery(it).block()
    }
    writer.println("}")
  }

  fun events(block: _EventsQuery.() -> Unit) {
    writer.print("events")
    writer.println(" {")
    writer.indent {
      _EventsQuery(it).block()
    }
    writer.println("}")
  }

  fun performers(block: _PerformersQuery.() -> Unit) {
    writer.print("performers")
    writer.println(" {")
    writer.indent {
      _PerformersQuery(it).block()
    }
    writer.println("}")
  }

  fun tags(block: _TagsQuery.() -> Unit) {
    writer.print("tags")
    writer.println(" {")
    writer.indent {
      _TagsQuery(it).block()
    }
    writer.println("}")
  }

  fun articles(block: _ArticlesQuery.() -> Unit) {
    writer.print("articles")
    writer.println(" {")
    writer.indent {
      _ArticlesQuery(it).block()
    }
    writer.println("}")
  }

  fun search(input: GlobalSearchInput, block: _GlobalSearchResultsQuery.() -> Unit) {
    writer.print("search")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "GlobalSearchInput!",
        GlobalSearchInput.serializer(), input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _GlobalSearchResultsQuery(it).block()
    }
    writer.println("}")
  }

  fun subscriptions(block: _SubscriptionsQuery.() -> Unit) {
    writer.print("subscriptions")
    writer.println(" {")
    writer.indent {
      _SubscriptionsQuery(it).block()
    }
    writer.println("}")
  }

  fun entity(path: String, block: _EntityQuery.() -> Unit) {
    writer.print("entity")
    writer.print("(")
    writer.print("path: \$${writer.variable("path", "String!", path)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _EntityQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _MutationQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun admin(block: _AdminMutationQuery.() -> Unit) {
    writer.print("admin")
    writer.println(" {")
    writer.indent {
      _AdminMutationQuery(it).block()
    }
    writer.println("}")
  }

  fun subscriptions(block: _SubscriptionsMutatorQuery.() -> Unit) {
    writer.print("subscriptions")
    writer.println(" {")
    writer.indent {
      _SubscriptionsMutatorQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _GlobalSearchResultsQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun events(block: _EventQuery.() -> Unit) {
    writer.print("events")
    writer.println(" {")
    writer.indent {
      _EventQuery(it).block()
    }
    writer.println("}")
  }

  fun venues(block: _VenueQuery.() -> Unit) {
    writer.print("venues")
    writer.println(" {")
    writer.indent {
      _VenueQuery(it).block()
    }
    writer.println("}")
  }

  fun tags(block: _TagQuery.() -> Unit) {
    writer.print("tags")
    writer.println(" {")
    writer.indent {
      _TagQuery(it).block()
    }
    writer.println("}")
  }

  fun performers(block: _PerformerQuery.() -> Unit) {
    writer.print("performers")
    writer.println(" {")
    writer.indent {
      _PerformerQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _EntityQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val id: Unit
    get() {
      writer.println("id")
    }

  val name: Unit
    get() {
      writer.println("name")
    }

  val description: Unit
    get() {
      writer.println("description")
    }

  val path: Unit
    get() {
      writer.println("path")
    }

  fun images(block: _ImageQuery.() -> Unit) {
    writer.print("images")
    writer.println(" {")
    writer.indent {
      _ImageQuery(it).block()
    }
    writer.println("}")
  }

  fun tags(block: _TagQuery.() -> Unit) {
    writer.print("tags")
    writer.println(" {")
    writer.indent {
      _TagQuery(it).block()
    }
    writer.println("}")
  }

  fun events(input: EventSearch, block: _EventSearchResultsQuery.() -> Unit) {
    writer.print("events")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "EventSearch!", EventSearch.serializer(),
        input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _EventSearchResultsQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _VenuesQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun root(block: _VenueQuery.() -> Unit) {
    writer.print("root")
    writer.println(" {")
    writer.indent {
      _VenueQuery(it).block()
    }
    writer.println("}")
  }

  fun venue(id: ID, block: _VenueQuery.() -> Unit) {
    writer.print("venue")
    writer.print("(")
    writer.print("id: \$${writer.variable("id", "ID!", id)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _VenueQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _VenueQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val id: Unit
    get() {
      writer.println("id")
    }

  val name: Unit
    get() {
      writer.println("name")
    }

  val description: Unit
    get() {
      writer.println("description")
    }

  val path: Unit
    get() {
      writer.println("path")
    }

  val status: Unit
    get() {
      writer.println("status")
    }

  val redirect: Unit
    get() {
      writer.println("redirect")
    }

  val ticketsUrl: Unit
    get() {
      writer.println("ticketsUrl")
    }

  fun parent(block: _VenueQuery.() -> Unit) {
    writer.print("parent")
    writer.println(" {")
    writer.indent {
      _VenueQuery(it).block()
    }
    writer.println("}")
  }

  fun links(block: _SocialLinkQuery.() -> Unit) {
    writer.print("links")
    writer.println(" {")
    writer.indent {
      _SocialLinkQuery(it).block()
    }
    writer.println("}")
  }

  fun images(block: _ImageQuery.() -> Unit) {
    writer.print("images")
    writer.println(" {")
    writer.indent {
      _ImageQuery(it).block()
    }
    writer.println("}")
  }

  fun tags(block: _TagQuery.() -> Unit) {
    writer.print("tags")
    writer.println(" {")
    writer.indent {
      _TagQuery(it).block()
    }
    writer.println("}")
  }

  fun children(block: _VenueQuery.() -> Unit) {
    writer.print("children")
    writer.println(" {")
    writer.indent {
      _VenueQuery(it).block()
    }
    writer.println("}")
  }

  fun location(block: _GPSCoordinatesQuery.() -> Unit) {
    writer.print("location")
    writer.println(" {")
    writer.indent {
      _GPSCoordinatesQuery(it).block()
    }
    writer.println("}")
  }

  fun address(block: _AddressQuery.() -> Unit) {
    writer.print("address")
    writer.println(" {")
    writer.indent {
      _AddressQuery(it).block()
    }
    writer.println("}")
  }

  fun events(input: EventSearch, block: _EventSearchResultsQuery.() -> Unit) {
    writer.print("events")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "EventSearch!", EventSearch.serializer(),
        input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _EventSearchResultsQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _SocialLinkQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val type: Unit
    get() {
      writer.println("type")
    }

  val locator: Unit
    get() {
      writer.println("locator")
    }
}

@GraphKtQuery
class _AddressQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val street: Unit
    get() {
      writer.println("street")
    }

  val city: Unit
    get() {
      writer.println("city")
    }

  val state: Unit
    get() {
      writer.println("state")
    }

  val zip: Unit
    get() {
      writer.println("zip")
    }

  val country: Unit
    get() {
      writer.println("country")
    }
}

@GraphKtQuery
class _GPSCoordinatesQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val latitude: Unit
    get() {
      writer.println("latitude")
    }

  val longitude: Unit
    get() {
      writer.println("longitude")
    }
}

@GraphKtQuery
class _ImageQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val id: Unit
    get() {
      writer.println("id")
    }

  val uri: Unit
    get() {
      writer.println("uri")
    }

  val width: Unit
    get() {
      writer.println("width")
    }

  val height: Unit
    get() {
      writer.println("height")
    }

  fun withWidth(width: Int, block: _ImageQuery.() -> Unit) {
    writer.print("withWidth")
    writer.print("(")
    writer.print("width: \$${writer.variable("width", "Int!", width)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _ImageQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _TagsQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun tag(id: ID, block: _TagQuery.() -> Unit) {
    writer.print("tag")
    writer.print("(")
    writer.print("id: \$${writer.variable("id", "ID!", id)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _TagQuery(it).block()
    }
    writer.println("}")
  }

  fun root(block: _TagQuery.() -> Unit) {
    writer.print("root")
    writer.println(" {")
    writer.indent {
      _TagQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _TagQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val id: Unit
    get() {
      writer.println("id")
    }

  val path: Unit
    get() {
      writer.println("path")
    }

  val name: Unit
    get() {
      writer.println("name")
    }

  val description: Unit
    get() {
      writer.println("description")
    }

  val pageTitle: Unit
    get() {
      writer.println("pageTitle")
    }

  fun images(block: _ImageQuery.() -> Unit) {
    writer.print("images")
    writer.println(" {")
    writer.indent {
      _ImageQuery(it).block()
    }
    writer.println("}")
  }

  fun events(input: EventSearch, block: _EventSearchResultsQuery.() -> Unit) {
    writer.print("events")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "EventSearch!", EventSearch.serializer(),
        input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _EventSearchResultsQuery(it).block()
    }
    writer.println("}")
  }

  fun tags(block: _TagQuery.() -> Unit) {
    writer.print("tags")
    writer.println(" {")
    writer.indent {
      _TagQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _EventsQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun event(id: ID, block: _EventQuery.() -> Unit) {
    writer.print("event")
    writer.print("(")
    writer.print("id: \$${writer.variable("id", "ID!", id)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _EventQuery(it).block()
    }
    writer.println("}")
  }

  fun search(input: EventSearch, block: _EventSearchResultsQuery.() -> Unit) {
    writer.print("search")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "EventSearch!", EventSearch.serializer(),
        input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _EventSearchResultsQuery(it).block()
    }
    writer.println("}")
  }

  fun recentlyActive(input: EventSearch, block: _EventSearchResultsQuery.() -> Unit) {
    writer.print("recentlyActive")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "EventSearch!", EventSearch.serializer(),
        input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _EventSearchResultsQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _EventQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val id: Unit
    get() {
      writer.println("id")
    }

  val path: Unit
    get() {
      writer.println("path")
    }

  val name: Unit
    get() {
      writer.println("name")
    }

  val description: Unit
    get() {
      writer.println("description")
    }

  val date: Unit
    get() {
      writer.println("date")
    }

  val time: Unit
    get() {
      writer.println("time")
    }

  val endDate: Unit
    get() {
      writer.println("endDate")
    }

  val endTime: Unit
    get() {
      writer.println("endTime")
    }

  val duration: Unit
    get() {
      writer.println("duration")
    }

  val ticketsUrl: Unit
    get() {
      writer.println("ticketsUrl")
    }

  val disabled: Unit
    get() {
      writer.println("disabled")
    }

  val sourceUrl: Unit
    get() {
      writer.println("sourceUrl")
    }

  fun venue(block: _VenueQuery.() -> Unit) {
    writer.print("venue")
    writer.println(" {")
    writer.indent {
      _VenueQuery(it).block()
    }
    writer.println("}")
  }

  fun images(block: _ImageQuery.() -> Unit) {
    writer.print("images")
    writer.println(" {")
    writer.indent {
      _ImageQuery(it).block()
    }
    writer.println("}")
  }

  fun tags(block: _TagQuery.() -> Unit) {
    writer.print("tags")
    writer.println(" {")
    writer.indent {
      _TagQuery(it).block()
    }
    writer.println("}")
  }

  fun performers(block: _PerformerQuery.() -> Unit) {
    writer.print("performers")
    writer.println(" {")
    writer.indent {
      _PerformerQuery(it).block()
    }
    writer.println("}")
  }

  fun parent(block: _EventQuery.() -> Unit) {
    writer.print("parent")
    writer.println(" {")
    writer.indent {
      _EventQuery(it).block()
    }
    writer.println("}")
  }

  fun children(block: _EventQuery.() -> Unit) {
    writer.print("children")
    writer.println(" {")
    writer.indent {
      _EventQuery(it).block()
    }
    writer.println("}")
  }

  fun events(input: EventSearch, block: _EventSearchResultsQuery.() -> Unit) {
    writer.print("events")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "EventSearch!", EventSearch.serializer(),
        input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _EventSearchResultsQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _PerformersQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun performer(id: ID, block: _PerformerQuery.() -> Unit) {
    writer.print("performer")
    writer.print("(")
    writer.print("id: \$${writer.variable("id", "ID!", id)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _PerformerQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _PerformerQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val id: Unit
    get() {
      writer.println("id")
    }

  val path: Unit
    get() {
      writer.println("path")
    }

  val name: Unit
    get() {
      writer.println("name")
    }

  val description: Unit
    get() {
      writer.println("description")
    }

  fun tags(block: _TagQuery.() -> Unit) {
    writer.print("tags")
    writer.println(" {")
    writer.indent {
      _TagQuery(it).block()
    }
    writer.println("}")
  }

  fun events(input: EventSearch, block: _EventSearchResultsQuery.() -> Unit) {
    writer.print("events")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "EventSearch!", EventSearch.serializer(),
        input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _EventSearchResultsQuery(it).block()
    }
    writer.println("}")
  }

  fun images(block: _ImageQuery.() -> Unit) {
    writer.print("images")
    writer.println(" {")
    writer.indent {
      _ImageQuery(it).block()
    }
    writer.println("}")
  }

  fun links(block: _SocialLinkQuery.() -> Unit) {
    writer.print("links")
    writer.println(" {")
    writer.indent {
      _SocialLinkQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _ArticleSearchResultQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun articles(block: _ArticleQuery.() -> Unit) {
    writer.print("articles")
    writer.println(" {")
    writer.indent {
      _ArticleQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _ArticlesQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun article(id: ID, block: _ArticleQuery.() -> Unit) {
    writer.print("article")
    writer.print("(")
    writer.print("id: \$${writer.variable("id", "ID!", id)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _ArticleQuery(it).block()
    }
    writer.println("}")
  }

  fun recent(input: ArticleSearchInput, block: _ArticleSearchResultQuery.() -> Unit) {
    writer.print("recent")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "ArticleSearchInput!",
        ArticleSearchInput.serializer(), input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _ArticleSearchResultQuery(it).block()
    }
    writer.println("}")
  }

  fun query(input: ArticleSearchInput, block: _ArticleSearchResultQuery.() -> Unit) {
    writer.print("query")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "ArticleSearchInput!",
        ArticleSearchInput.serializer(), input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _ArticleSearchResultQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _ArticleQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val id: Unit
    get() {
      writer.println("id")
    }

  val path: Unit
    get() {
      writer.println("path")
    }

  val title: Unit
    get() {
      writer.println("title")
    }

  val summary: Unit
    get() {
      writer.println("summary")
    }

  val description: Unit
    get() {
      writer.println("description")
    }

  val name: Unit
    get() {
      writer.println("name")
    }

  val content: Unit
    get() {
      writer.println("content")
    }

  val created: Unit
    get() {
      writer.println("created")
    }

  val updated: Unit
    get() {
      writer.println("updated")
    }

  val published: Unit
    get() {
      writer.println("published")
    }

  fun tags(block: _TagQuery.() -> Unit) {
    writer.print("tags")
    writer.println(" {")
    writer.indent {
      _TagQuery(it).block()
    }
    writer.println("}")
  }

  fun linkedEntities(block: _EntityQuery.() -> Unit) {
    writer.print("linkedEntities")
    writer.println(" {")
    writer.indent {
      _EntityQuery(it).block()
    }
    writer.println("}")
  }

  fun images(block: _ImageQuery.() -> Unit) {
    writer.print("images")
    writer.println(" {")
    writer.indent {
      _ImageQuery(it).block()
    }
    writer.println("}")
  }

  fun events(input: EventSearch, block: _EventSearchResultsQuery.() -> Unit) {
    writer.print("events")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "EventSearch!", EventSearch.serializer(),
        input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _EventSearchResultsQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _EventSearchResultsQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val pageToken: Unit
    get() {
      writer.println("pageToken")
    }

  fun events(block: _EventQuery.() -> Unit) {
    writer.print("events")
    writer.println(" {")
    writer.indent {
      _EventQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _SystemMutationQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun applicationEvent(input: SystemEventInput) {
    writer.print("applicationEvent")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "SystemEventInput!",
        SystemEventInput.serializer(), input)}")
    writer.print(")")
  }
}

@GraphKtQuery
class _SubscriptionsQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun userSubscriptions(userId: String, block: _UserSubscriptionsQuery.() -> Unit) {
    writer.print("userSubscriptions")
    writer.print("(")
    writer.print("userId: \$${writer.variable("userId", "String!", userId)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _UserSubscriptionsQuery(it).block()
    }
    writer.println("}")
  }

  fun mine(block: _UserSubscriptionsQuery.() -> Unit) {
    writer.print("mine")
    writer.println(" {")
    writer.indent {
      _UserSubscriptionsQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _UserSubscriptionsQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun subscriptions(block: _SubscriptionQuery.() -> Unit) {
    writer.print("subscriptions")
    writer.println(" {")
    writer.indent {
      _SubscriptionQuery(it).block()
    }
    writer.println("}")
  }

  fun subscription(entityPath: String, block: _SubscriptionQuery.() -> Unit) {
    writer.print("subscription")
    writer.print("(")
    writer.print("entityPath: \$${writer.variable("entityPath", "String!", entityPath)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _SubscriptionQuery(it).block()
    }
    writer.println("}")
  }

  fun events(input: EventSearch, block: _EventSearchResultsQuery.() -> Unit) {
    writer.print("events")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "EventSearch!", EventSearch.serializer(),
        input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _EventSearchResultsQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _SubscriptionQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val subscriber: Unit
    get() {
      writer.println("subscriber")
    }

  val date: Unit
    get() {
      writer.println("date")
    }

  val name: Unit
    get() {
      writer.println("name")
    }

  val description: Unit
    get() {
      writer.println("description")
    }

  fun target(block: _EntityQuery.() -> Unit) {
    writer.print("target")
    writer.println(" {")
    writer.indent {
      _EntityQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _SubscriptionsMutatorQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun subscribe(input: SubscriptionInput, block: _SubscriptionQuery.() -> Unit) {
    writer.print("subscribe")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "SubscriptionInput!",
        SubscriptionInput.serializer(), input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _SubscriptionQuery(it).block()
    }
    writer.println("}")
  }

  fun unsubscribe(input: SubscriptionInput) {
    writer.print("unsubscribe")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "SubscriptionInput!",
        SubscriptionInput.serializer(), input)}")
    writer.print(")")
  }
}

@GraphKtQuery
class _AdminMutationQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun venues(block: _AdminVenuesQuery.() -> Unit) {
    writer.print("venues")
    writer.println(" {")
    writer.indent {
      _AdminVenuesQuery(it).block()
    }
    writer.println("}")
  }

  fun events(block: _AdminEventsQuery.() -> Unit) {
    writer.print("events")
    writer.println(" {")
    writer.indent {
      _AdminEventsQuery(it).block()
    }
    writer.println("}")
  }

  fun performers(block: _AdminPerformersQuery.() -> Unit) {
    writer.print("performers")
    writer.println(" {")
    writer.indent {
      _AdminPerformersQuery(it).block()
    }
    writer.println("}")
  }

  fun articles(block: _AdminArticlesQuery.() -> Unit) {
    writer.print("articles")
    writer.println(" {")
    writer.indent {
      _AdminArticlesQuery(it).block()
    }
    writer.println("}")
  }

  fun tags(block: _AdminTagsQuery.() -> Unit) {
    writer.print("tags")
    writer.println(" {")
    writer.indent {
      _AdminTagsQuery(it).block()
    }
    writer.println("}")
  }

  fun images(block: _AdminImagesQuery.() -> Unit) {
    writer.print("images")
    writer.println(" {")
    writer.indent {
      _AdminImagesQuery(it).block()
    }
    writer.println("}")
  }

  fun loaders(block: _AdminLoadersQuery.() -> Unit) {
    writer.print("loaders")
    writer.println(" {")
    writer.indent {
      _AdminLoadersQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _AdminImagesQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun upload(input: ImageUpload, block: _ImageQuery.() -> Unit) {
    writer.print("upload")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "ImageUpload!", ImageUpload.serializer(),
        input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _ImageQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _AdminVenuesQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun venue(id: ID, block: _AdminVenueQuery.() -> Unit) {
    writer.print("venue")
    writer.print("(")
    writer.print("id: \$${writer.variable("id", "ID!", id)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _AdminVenueQuery(it).block()
    }
    writer.println("}")
  }

  fun add(
    id: ID,
    input: VenueUpdateInput,
    block: _AdminVenueQuery.() -> Unit
  ) {
    writer.print("add")
    writer.print("(")
    var count = 0
    if (count++ > 0) writer.print(", ")
    writer.print("id: \$${writer.variable("id", "ID!", id)}")
    if (count++ > 0) writer.print(", ")
    writer.print("input: \$${writer.variable("input", "VenueUpdateInput!",
        VenueUpdateInput.serializer(), input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _AdminVenueQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _AdminVenueQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val id: Unit
    get() {
      writer.println("id")
    }

  fun parent(block: _VenueQuery.() -> Unit) {
    writer.print("parent")
    writer.println(" {")
    writer.indent {
      _VenueQuery(it).block()
    }
    writer.println("}")
  }

  fun children(block: _VenueQuery.() -> Unit) {
    writer.print("children")
    writer.println(" {")
    writer.indent {
      _VenueQuery(it).block()
    }
    writer.println("}")
  }

  fun layers(block: _AdminVenueLayerQuery.() -> Unit) {
    writer.print("layers")
    writer.println(" {")
    writer.indent {
      _AdminVenueLayerQuery(it).block()
    }
    writer.println("}")
  }

  fun layer(id: ID, block: _AdminVenueLayerQuery.() -> Unit) {
    writer.print("layer")
    writer.print("(")
    writer.print("id: \$${writer.variable("id", "ID!", id)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _AdminVenueLayerQuery(it).block()
    }
    writer.println("}")
  }

  fun local(block: _AdminVenueLayerQuery.() -> Unit) {
    writer.print("local")
    writer.println(" {")
    writer.indent {
      _AdminVenueLayerQuery(it).block()
    }
    writer.println("}")
  }

  fun resolved(block: _VenueQuery.() -> Unit) {
    writer.print("resolved")
    writer.println(" {")
    writer.indent {
      _VenueQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _AdminVenueLayerQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val name: Unit
    get() {
      writer.println("name")
    }

  val description: Unit
    get() {
      writer.println("description")
    }

  val aliases: Unit
    get() {
      writer.println("aliases")
    }

  val ticketsUrl: Unit
    get() {
      writer.println("ticketsUrl")
    }

  val status: Unit
    get() {
      writer.println("status")
    }

  val rankingScore: Unit
    get() {
      writer.println("rankingScore")
    }

  fun address(block: _AddressQuery.() -> Unit) {
    writer.print("address")
    writer.println(" {")
    writer.indent {
      _AddressQuery(it).block()
    }
    writer.println("}")
  }

  fun location(block: _GPSCoordinatesQuery.() -> Unit) {
    writer.print("location")
    writer.println(" {")
    writer.indent {
      _GPSCoordinatesQuery(it).block()
    }
    writer.println("}")
  }

  fun links(block: _SocialLinkQuery.() -> Unit) {
    writer.print("links")
    writer.println(" {")
    writer.indent {
      _SocialLinkQuery(it).block()
    }
    writer.println("}")
  }

  fun tags(block: _TagQuery.() -> Unit) {
    writer.print("tags")
    writer.println(" {")
    writer.indent {
      _TagQuery(it).block()
    }
    writer.println("}")
  }

  fun images(block: _ImageQuery.() -> Unit) {
    writer.print("images")
    writer.println(" {")
    writer.indent {
      _ImageQuery(it).block()
    }
    writer.println("}")
  }

  fun redirect(block: _VenueQuery.() -> Unit) {
    writer.print("redirect")
    writer.println(" {")
    writer.indent {
      _VenueQuery(it).block()
    }
    writer.println("}")
  }

  fun update(input: VenueUpdateInput, block: _AdminVenueLayerQuery.() -> Unit) {
    writer.print("update")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "VenueUpdateInput!",
        VenueUpdateInput.serializer(), input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _AdminVenueLayerQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _AdminPerformersQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun performer(id: ID, block: _AdminPerformerQuery.() -> Unit) {
    writer.print("performer")
    writer.print("(")
    writer.print("id: \$${writer.variable("id", "ID!", id)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _AdminPerformerQuery(it).block()
    }
    writer.println("}")
  }

  fun add(
    id: ID,
    input: PerformerUpdateInput,
    block: _AdminPerformerQuery.() -> Unit
  ) {
    writer.print("add")
    writer.print("(")
    var count = 0
    if (count++ > 0) writer.print(", ")
    writer.print("id: \$${writer.variable("id", "ID!", id)}")
    if (count++ > 0) writer.print(", ")
    writer.print("input: \$${writer.variable("input", "PerformerUpdateInput!",
        PerformerUpdateInput.serializer(), input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _AdminPerformerQuery(it).block()
    }
    writer.println("}")
  }

  fun sourceSearch(text: String, block: _SourceDataSearchResultQuery.() -> Unit) {
    writer.print("sourceSearch")
    writer.print("(")
    writer.print("text: \$${writer.variable("text", "String!", text)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _SourceDataSearchResultQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _SourceDataSearchResultQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val id: Unit
    get() {
      writer.println("id")
    }

  val description: Unit
    get() {
      writer.println("description")
    }
}

@GraphKtQuery
class _AdminPerformerQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val id: Unit
    get() {
      writer.println("id")
    }

  fun layers(block: _AdminPerformerLayerQuery.() -> Unit) {
    writer.print("layers")
    writer.println(" {")
    writer.indent {
      _AdminPerformerLayerQuery(it).block()
    }
    writer.println("}")
  }

  fun layer(id: ID, block: _AdminPerformerLayerQuery.() -> Unit) {
    writer.print("layer")
    writer.print("(")
    writer.print("id: \$${writer.variable("id", "ID!", id)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _AdminPerformerLayerQuery(it).block()
    }
    writer.println("}")
  }

  fun local(block: _AdminPerformerLayerQuery.() -> Unit) {
    writer.print("local")
    writer.println(" {")
    writer.indent {
      _AdminPerformerLayerQuery(it).block()
    }
    writer.println("}")
  }

  fun resolved(block: _PerformerQuery.() -> Unit) {
    writer.print("resolved")
    writer.println(" {")
    writer.indent {
      _PerformerQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _AdminPerformerLayerQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val name: Unit
    get() {
      writer.println("name")
    }

  val description: Unit
    get() {
      writer.println("description")
    }

  val aliases: Unit
    get() {
      writer.println("aliases")
    }

  val wikiIdentifier: Unit
    get() {
      writer.println("wikiIdentifier")
    }

  val rankingScore: Unit
    get() {
      writer.println("rankingScore")
    }

  fun images(block: _ImageQuery.() -> Unit) {
    writer.print("images")
    writer.println(" {")
    writer.indent {
      _ImageQuery(it).block()
    }
    writer.println("}")
  }

  fun tags(block: _TagQuery.() -> Unit) {
    writer.print("tags")
    writer.println(" {")
    writer.indent {
      _TagQuery(it).block()
    }
    writer.println("}")
  }

  fun update(input: PerformerUpdateInput, block: _AdminPerformerLayerQuery.() -> Unit) {
    writer.print("update")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "PerformerUpdateInput!",
        PerformerUpdateInput.serializer(), input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _AdminPerformerLayerQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _AdminEventsQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun event(id: ID, block: _AdminEventQuery.() -> Unit) {
    writer.print("event")
    writer.print("(")
    writer.print("id: \$${writer.variable("id", "ID!", id)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _AdminEventQuery(it).block()
    }
    writer.println("}")
  }

  fun reviewNext(block: _AdminEventQuery.() -> Unit) {
    writer.print("reviewNext")
    writer.println(" {")
    writer.indent {
      _AdminEventQuery(it).block()
    }
    writer.println("}")
  }

  fun add(input: EventUpdateInput, block: _AdminEventQuery.() -> Unit) {
    writer.print("add")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "EventUpdateInput!",
        EventUpdateInput.serializer(), input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _AdminEventQuery(it).block()
    }
    writer.println("}")
  }

  fun forReview(block: _EventQuery.() -> Unit) {
    writer.print("forReview")
    writer.println(" {")
    writer.indent {
      _EventQuery(it).block()
    }
    writer.println("}")
  }

  fun merge(input: MergeInput, block: _AdminEventQuery.() -> Unit) {
    writer.print("merge")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "MergeInput!", MergeInput.serializer(),
        input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _AdminEventQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _AdminEventQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val id: Unit
    get() {
      writer.println("id")
    }

  fun layers(block: _AdminEventLayerQuery.() -> Unit) {
    writer.print("layers")
    writer.println(" {")
    writer.indent {
      _AdminEventLayerQuery(it).block()
    }
    writer.println("}")
  }

  fun layer(id: ID, block: _AdminEventLayerQuery.() -> Unit) {
    writer.print("layer")
    writer.print("(")
    writer.print("id: \$${writer.variable("id", "ID!", id)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _AdminEventLayerQuery(it).block()
    }
    writer.println("}")
  }

  fun local(block: _AdminEventLayerQuery.() -> Unit) {
    writer.print("local")
    writer.println(" {")
    writer.indent {
      _AdminEventLayerQuery(it).block()
    }
    writer.println("}")
  }

  fun resolved(block: _EventQuery.() -> Unit) {
    writer.print("resolved")
    writer.println(" {")
    writer.indent {
      _EventQuery(it).block()
    }
    writer.println("}")
  }

  fun parent(block: _EventQuery.() -> Unit) {
    writer.print("parent")
    writer.println(" {")
    writer.indent {
      _EventQuery(it).block()
    }
    writer.println("}")
  }

  fun children(block: _EventQuery.() -> Unit) {
    writer.print("children")
    writer.println(" {")
    writer.indent {
      _EventQuery(it).block()
    }
    writer.println("}")
  }

  fun review(input: EventUpdateInput) {
    writer.print("review")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "EventUpdateInput!",
        EventUpdateInput.serializer(), input)}")
    writer.print(")")
  }

  fun rebuild(message: String?) {
    writer.print("rebuild")
    writer.print("(")
    if (message != null) {
      writer.print("message: \$${writer.variable("message", "String", message)}")
    } else {
      writer.print("message: \$${writer.variable("message", "String", null)}")
    }
    writer.print(")")
  }

  fun disable(delete: Boolean) {
    writer.print("disable")
    writer.print("(")
    writer.print("delete: \$${writer.variable("delete", "Boolean!", delete)}")
    writer.print(")")
  }
}

@GraphKtQuery
class _AdminEventLayerQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val layerId: Unit
    get() {
      writer.println("layerId")
    }

  val name: Unit
    get() {
      writer.println("name")
    }

  val description: Unit
    get() {
      writer.println("description")
    }

  val date: Unit
    get() {
      writer.println("date")
    }

  val time: Unit
    get() {
      writer.println("time")
    }

  val duration: Unit
    get() {
      writer.println("duration")
    }

  val expiration: Unit
    get() {
      writer.println("expiration")
    }

  val ticketsUrl: Unit
    get() {
      writer.println("ticketsUrl")
    }

  val sourceUrl: Unit
    get() {
      writer.println("sourceUrl")
    }

  val aliases: Unit
    get() {
      writer.println("aliases")
    }

  val lastUpdated: Unit
    get() {
      writer.println("lastUpdated")
    }

  val excluded: Unit
    get() {
      writer.println("excluded")
    }

  val rankingScore: Unit
    get() {
      writer.println("rankingScore")
    }

  fun images(block: _ImageQuery.() -> Unit) {
    writer.print("images")
    writer.println(" {")
    writer.indent {
      _ImageQuery(it).block()
    }
    writer.println("}")
  }

  fun excludedImages(block: _ImageQuery.() -> Unit) {
    writer.print("excludedImages")
    writer.println(" {")
    writer.indent {
      _ImageQuery(it).block()
    }
    writer.println("}")
  }

  fun tags(block: _TagQuery.() -> Unit) {
    writer.print("tags")
    writer.println(" {")
    writer.indent {
      _TagQuery(it).block()
    }
    writer.println("}")
  }

  fun excludedTags(block: _TagQuery.() -> Unit) {
    writer.print("excludedTags")
    writer.println(" {")
    writer.indent {
      _TagQuery(it).block()
    }
    writer.println("}")
  }

  fun performers(block: _PerformerQuery.() -> Unit) {
    writer.print("performers")
    writer.println(" {")
    writer.indent {
      _PerformerQuery(it).block()
    }
    writer.println("}")
  }

  fun venue(block: _VenueQuery.() -> Unit) {
    writer.print("venue")
    writer.println(" {")
    writer.indent {
      _VenueQuery(it).block()
    }
    writer.println("}")
  }

  fun update(input: EventUpdateInput, block: _AdminEventLayerQuery.() -> Unit) {
    writer.print("update")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "EventUpdateInput!",
        EventUpdateInput.serializer(), input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _AdminEventLayerQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _AdminArticlesQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun article(id: ID, block: _AdminArticleQuery.() -> Unit) {
    writer.print("article")
    writer.print("(")
    writer.print("id: \$${writer.variable("id", "ID!", id)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _AdminArticleQuery(it).block()
    }
    writer.println("}")
  }

  fun new(
    id: ID?,
    input: ArticleInput,
    block: _AdminArticleQuery.() -> Unit
  ) {
    writer.print("new")
    writer.print("(")
    var count = 0
    if (count++ > 0) writer.print(", ")
    if (id != null) {
      writer.print("id: \$${writer.variable("id", "ID", id)}")
    } else {
      writer.print("id: \$${writer.variable("id", "ID", null)}")
    }
    if (count++ > 0) writer.print(", ")
    writer.print("input: \$${writer.variable("input", "ArticleInput!", ArticleInput.serializer(),
        input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _AdminArticleQuery(it).block()
    }
    writer.println("}")
  }

  fun drafts(block: _AdminArticleQuery.() -> Unit) {
    writer.print("drafts")
    writer.println(" {")
    writer.indent {
      _AdminArticleQuery(it).block()
    }
    writer.println("}")
  }

  fun content(id: ID, block: _AdminArticleQuery.() -> Unit) {
    writer.print("content")
    writer.print("(")
    writer.print("id: \$${writer.variable("id", "ID!", id)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _AdminArticleQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _AdminArticleQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val id: Unit
    get() {
      writer.println("id")
    }

  val title: Unit
    get() {
      writer.println("title")
    }

  val summary: Unit
    get() {
      writer.println("summary")
    }

  val content: Unit
    get() {
      writer.println("content")
    }

  val created: Unit
    get() {
      writer.println("created")
    }

  val updated: Unit
    get() {
      writer.println("updated")
    }

  val published: Unit
    get() {
      writer.println("published")
    }

  fun links(block: _EntityQuery.() -> Unit) {
    writer.print("links")
    writer.println(" {")
    writer.indent {
      _EntityQuery(it).block()
    }
    writer.println("}")
  }

  fun images(block: _ImageQuery.() -> Unit) {
    writer.print("images")
    writer.println(" {")
    writer.indent {
      _ImageQuery(it).block()
    }
    writer.println("}")
  }

  fun update(input: ArticleInput, block: _AdminArticleQuery.() -> Unit) {
    writer.print("update")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "ArticleInput!", ArticleInput.serializer(),
        input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _AdminArticleQuery(it).block()
    }
    writer.println("}")
  }

  fun publish(time: EventInstant?, block: _AdminArticleQuery.() -> Unit) {
    writer.print("publish")
    writer.print("(")
    if (time != null) {
      writer.print("time: \$${writer.variable("time", "EventInstant", EventInstant.serializer(),
          time)}")
    } else {
      writer.print("time: \$${writer.variable("time", "EventInstant", null)}")
    }
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _AdminArticleQuery(it).block()
    }
    writer.println("}")
  }

  fun unpublish(block: _AdminArticleQuery.() -> Unit) {
    writer.print("unpublish")
    writer.println(" {")
    writer.indent {
      _AdminArticleQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _AdminTagsQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun tag(id: ID, block: _AdminTagQuery.() -> Unit) {
    writer.print("tag")
    writer.print("(")
    writer.print("id: \$${writer.variable("id", "ID!", id)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _AdminTagQuery(it).block()
    }
    writer.println("}")
  }

  fun add(
    id: ID,
    input: TagUpdateInput,
    block: _AdminTagQuery.() -> Unit
  ) {
    writer.print("add")
    writer.print("(")
    var count = 0
    if (count++ > 0) writer.print(", ")
    writer.print("id: \$${writer.variable("id", "ID!", id)}")
    if (count++ > 0) writer.print(", ")
    writer.print("input: \$${writer.variable("input", "TagUpdateInput!",
        TagUpdateInput.serializer(), input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _AdminTagQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _AdminTagQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val id: Unit
    get() {
      writer.println("id")
    }

  fun layers(block: _AdminTagLayerQuery.() -> Unit) {
    writer.print("layers")
    writer.println(" {")
    writer.indent {
      _AdminTagLayerQuery(it).block()
    }
    writer.println("}")
  }

  fun layer(id: ID, block: _AdminTagLayerQuery.() -> Unit) {
    writer.print("layer")
    writer.print("(")
    writer.print("id: \$${writer.variable("id", "ID!", id)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _AdminTagLayerQuery(it).block()
    }
    writer.println("}")
  }

  fun local(block: _AdminTagLayerQuery.() -> Unit) {
    writer.print("local")
    writer.println(" {")
    writer.indent {
      _AdminTagLayerQuery(it).block()
    }
    writer.println("}")
  }

  fun resolved(block: _TagQuery.() -> Unit) {
    writer.print("resolved")
    writer.println(" {")
    writer.indent {
      _TagQuery(it).block()
    }
    writer.println("}")
  }

  fun children(block: _TagQuery.() -> Unit) {
    writer.print("children")
    writer.println(" {")
    writer.indent {
      _TagQuery(it).block()
    }
    writer.println("}")
  }

  fun parent(block: _TagQuery.() -> Unit) {
    writer.print("parent")
    writer.println(" {")
    writer.indent {
      _TagQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _AdminTagLayerQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val name: Unit
    get() {
      writer.println("name")
    }

  val description: Unit
    get() {
      writer.println("description")
    }

  val childLimit: Unit
    get() {
      writer.println("childLimit")
    }

  val shadow: Unit
    get() {
      writer.println("shadow")
    }

  val pageTitle: Unit
    get() {
      writer.println("pageTitle")
    }

  val rankingScore: Unit
    get() {
      writer.println("rankingScore")
    }

  fun images(block: _ImageQuery.() -> Unit) {
    writer.print("images")
    writer.println(" {")
    writer.indent {
      _ImageQuery(it).block()
    }
    writer.println("}")
  }

  fun parent(block: _TagQuery.() -> Unit) {
    writer.print("parent")
    writer.println(" {")
    writer.indent {
      _TagQuery(it).block()
    }
    writer.println("}")
  }

  fun impliedTags(block: _TagQuery.() -> Unit) {
    writer.print("impliedTags")
    writer.println(" {")
    writer.indent {
      _TagQuery(it).block()
    }
    writer.println("}")
  }

  fun update(input: TagUpdateInput, block: _AdminTagLayerQuery.() -> Unit) {
    writer.print("update")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "TagUpdateInput!",
        TagUpdateInput.serializer(), input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _AdminTagLayerQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _AdminLoadersQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun modules(block: _AdminLoaderModuleQuery.() -> Unit) {
    writer.print("modules")
    writer.println(" {")
    writer.indent {
      _AdminLoaderModuleQuery(it).block()
    }
    writer.println("}")
  }

  fun module(id: ID, block: _AdminLoaderModuleQuery.() -> Unit) {
    writer.print("module")
    writer.print("(")
    writer.print("id: \$${writer.variable("id", "ID!", id)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _AdminLoaderModuleQuery(it).block()
    }
    writer.println("}")
  }

  fun loaders(block: _AdminLoaderQuery.() -> Unit) {
    writer.print("loaders")
    writer.println(" {")
    writer.indent {
      _AdminLoaderQuery(it).block()
    }
    writer.println("}")
  }

  fun loader(id: ID, block: _AdminLoaderQuery.() -> Unit) {
    writer.print("loader")
    writer.print("(")
    writer.print("id: \$${writer.variable("id", "ID!", id)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _AdminLoaderQuery(it).block()
    }
    writer.println("}")
  }

  fun registerLoader(
    id: ID,
    input: LoaderConfiguration,
    block: _AdminLoaderQuery.() -> Unit
  ) {
    writer.print("registerLoader")
    writer.print("(")
    var count = 0
    if (count++ > 0) writer.print(", ")
    writer.print("id: \$${writer.variable("id", "ID!", id)}")
    if (count++ > 0) writer.print(", ")
    writer.print("input: \$${writer.variable("input", "LoaderConfiguration!",
        LoaderConfiguration.serializer(), input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _AdminLoaderQuery(it).block()
    }
    writer.println("}")
  }

  fun deregister(id: ID) {
    writer.print("deregister")
    writer.print("(")
    writer.print("id: \$${writer.variable("id", "ID!", id)}")
    writer.print(")")
  }
}

@GraphKtQuery
class _AdminLoaderModuleQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val id: Unit
    get() {
      writer.println("id")
    }

  val name: Unit
    get() {
      writer.println("name")
    }
}

@GraphKtQuery
class _AdminLoaderQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val id: Unit
    get() {
      writer.println("id")
    }

  val name: Unit
    get() {
      writer.println("name")
    }

  val schedule: Unit
    get() {
      writer.println("schedule")
    }

  val parameters: Unit
    get() {
      writer.println("parameters")
    }

  fun module(block: _AdminLoaderModuleQuery.() -> Unit) {
    writer.print("module")
    writer.println(" {")
    writer.indent {
      _AdminLoaderModuleQuery(it).block()
    }
    writer.println("}")
  }

  fun update(input: LoaderConfiguration, block: _AdminLoaderQuery.() -> Unit) {
    writer.print("update")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "LoaderConfiguration!",
        LoaderConfiguration.serializer(), input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _AdminLoaderQuery(it).block()
    }
    writer.println("}")
  }

  fun recentExecutions(block: _LoaderExecutionQuery.() -> Unit) {
    writer.print("recentExecutions")
    writer.println(" {")
    writer.indent {
      _LoaderExecutionQuery(it).block()
    }
    writer.println("}")
  }

  fun execute(input: LoaderExecutionInput?) {
    writer.print("execute")
    writer.print("(")
    if (input != null) {
      writer.print("input: \$${writer.variable("input", "LoaderExecutionInput",
          LoaderExecutionInput.serializer(), input)}")
    } else {
      writer.print("input: \$${writer.variable("input", "LoaderExecutionInput", null)}")
    }
    writer.print(")")
  }

  fun readInternalData(key: String) {
    writer.print("readInternalData")
    writer.print("(")
    writer.print("key: \$${writer.variable("key", "String!", key)}")
    writer.print(")")
  }

  fun saveInternalData(key: String, data: String) {
    writer.print("saveInternalData")
    writer.print("(")
    var count = 0
    if (count++ > 0) writer.print(", ")
    writer.print("key: \$${writer.variable("key", "String!", key)}")
    if (count++ > 0) writer.print(", ")
    writer.print("data: \$${writer.variable("data", "String!", data)}")
    writer.print(")")
  }

  fun lastLoad(block: _LoaderExecutionQuery.() -> Unit) {
    writer.print("lastLoad")
    writer.println(" {")
    writer.indent {
      _LoaderExecutionQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _LoaderExecutionQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val ts: Unit
    get() {
      writer.println("ts")
    }

  val events: Unit
    get() {
      writer.println("events")
    }
}

suspend fun GraphQLClient.query(name: String? = null, block: _QueryQuery.() -> Unit): Query {
  val result = executeAndParse(name, json, ::Query) {
    this.type = "query"
    _QueryQuery(this).block()
  }
  return result
}

suspend fun GraphQLClient.mutation(name: String? = null, block: _MutationQuery.() -> Unit):
    Mutation {
  val result = executeAndParse(name, json, ::Mutation) {
    this.type = "mutation"
    _MutationQuery(this).block()
  }
  return result
}
