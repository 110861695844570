package com.steamstreet.vegasful.browser.account

import com.steamstreet.aws.cognito.CognitoClient
import com.steamstreet.aws.cognito.CognitoException
import kotlinx.browser.window
import kotlinx.coroutines.launch
import kotlinx.html.InputType
import kotlinx.html.a
import kotlinx.html.input
import org.w3c.dom.Element
import org.w3c.dom.HTMLInputElement

/**
 * Display and manage the registration dialog
 */
fun register(element: Element, cognito: CognitoClient) {
    val dialog = AccountDialog(element)

    dialog.header("Sign Up") {
        +"Already a member? "
        a(href = "/login") {
            +"Log in"
        }
    }

    suspend fun submit() {
        dialog.clearErrors()

        val username =
            (dialog.findElementFromClassName("login-email") as? HTMLInputElement)?.value
        val password =
            (dialog.findElementFromClassName("login-password") as? HTMLInputElement)?.value
        if (!username.isNullOrBlank() && !password.isNullOrBlank()) {
            try {
                val result = cognito.register(username, password)
                if (!result.userConfirmed) {
                    dialog.header("Sign Up Success!", "Please check your email for confirmation.")
                    dialog.clearContent()
                } else {
                    Account.navigate("/my-guide")
                }
            } catch (e: CognitoException) {
                when (e.code) {
                    "UsernameExistsException" -> {
                        // try to log in with the same credentials
                        try {
                            cognito.login(username, password)
                            Account.navigate("/my-guide")
                        } catch (e: CognitoException) {
                            // if that fails, show the error
                            dialog.showError {
                                +"This email is already registered. "
                                a(href = "/login") {
                                    +"Sign in here."
                                }
                            }
                        }
                    }

                    "InvalidPasswordException" -> {
                        dialog.showError("Your password must be at least 8 characters long and include a number.")
                    }

                    "InvalidParameterException" -> { // username is not an email
                        dialog.showError("This is not a valid email address.")
                    }

                    else -> {
                        dialog.showError(e.message ?: "Sign up failed.")
                    }
                }
            }
        }
    }

    dialog.content {
        inputField("Email") {
            input(InputType.text, classes = "login-email")
        }

        inputField("Password") {
            input(InputType.password, classes = "login-password")
        }
    }

    dialog.actionButton("Sign up") {
        submit()
    }
    dialog.submitFunction = ::submit
    dialog.focus("login-email")
}

fun confirmRegistration(element: Element, cognito: CognitoClient, code: String, email: String) {
    val dialog = AccountDialog(element)

    dialog.header("Confirming Registration...") {}

    dialog.scope.launch {
        try {
            cognito.confirmRegistration(email, code)
            window.location.href = "/login"
        } catch (e: CognitoException) {
            dialog.header("Confirmation Failed") {}
            dialog.showError(e.message ?: "Your registration could not be confirmed.")
        }
    }
}