package com.steamstreet.vegasful.html.components

import com.steamstreet.vegasful.css.css
import kotlinx.css.marginTop
import kotlinx.css.px
import kotlinx.html.FlowContent
import kotlinx.html.div
import kotlinx.html.unsafe

fun FlowContent.descriptionContent(description: String?) {
    if (description != null) {
        div {
            css {
                "p" {
                    firstChild {
                        marginTop = 0.px
                    }
                }
            }
            unsafe {
                +description
            }
        }
    }

}