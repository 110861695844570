package com.steamstreet.vegasful.html.components

import com.steamstreet.vegasful.css.css
import kotlinx.css.fontSize
import kotlinx.css.px
import kotlinx.html.FlowContent
import kotlinx.html.i
import kotlinx.html.span

enum class IconType(val prefix: String) {
    Solid("fas"),
    Regular("far"),
    Light("far"),
    Duotone("fad"),
    Brand("fab")
}

enum class SpinType(val type: String) {
    Spin("spin"),
    Pulse("pulse"),
    None("")
}

fun FlowContent.icon(id: String, type: IconType = IconType.Solid, spin: SpinType = SpinType.None) {
    i("${type.prefix} fa-$id ${if (spin != SpinType.None) "fa-${spin.type}" else ""}") {}
}

fun FlowContent.spinner() = icon("spinner", spin = SpinType.Spin)

fun FlowContent.largeSpinner() {
    span {
        css {
            fontSize = 36.px
        }
        spinner()
    }
}