package com.steamstreet.vegasful.css

import kotlinx.browser.document
import kotlinx.css.CssBuilder
import kotlinx.html.CommonAttributeGroupFacade
import kotlinx.html.classes

actual fun CommonAttributeGroupFacade.css(builder: CssBuilder.() -> Unit) {
    val css = CssBuilder().apply(builder)
    val className = JsCssManager.addCss(css)
    this.classes += className
}

object JsCssManager : CssManager {
    private val cssKeys = HashSet<String>()

    override fun addCss(css: CssBuilder): String {
        val cssString = css.toString()
        val hashCode = cssString.hashCode().toString()

        if (!cssKeys.contains(hashCode)) {
            val cssWriter = StringBuilder()
            val itemSelector = ".cv$hashCode"
            writeStyleSheet(itemSelector, css, cssWriter)
            val styleElement = document.createElement("style")
            styleElement.textContent = cssWriter.toString()
            document.head!!.appendChild(styleElement)
        }
        return "cv$hashCode"
    }
}