package com.steamstreet.aws.cognito

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.engine.js.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.request.forms.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.browser.window
import kotlinx.coroutines.*
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.coroutines.resumeWithException
import kotlin.coroutines.suspendCoroutine

/**
 * Provides a browser based API for interacting with Cognito.
 */
class CognitoClient(
    private val userPoolId: String,
    private val clientId: String
) {
    private val userPool: CognitoUserPool = CognitoUserPool(object : ICognitoUserPoolData {
        override var UserPoolId: String = userPoolId
        override var ClientId: String = clientId
    })

    fun isLoggedIn(): Boolean {
        return userPool.getCurrentUser() != null
    }

    suspend fun getAccessToken(): String? {
        return getSession()?.getAccessToken()?.getJwtToken()
    }

    private suspend fun getSession(): CognitoUserSession? {
        return suspendCoroutine { cont ->
            val currentUser = userPool.getCurrentUser()
            if (currentUser == null) {
                cont.resumeWith(Result.success(null))
            } else {
                currentUser.getSession { error: Nothing?, session: CognitoUserSession ->
                    cont.resumeWith(Result.success(session))
                }
            }
        }
    }

    suspend fun confirmRegistration(email: String, code: String): Any {
        return suspendCoroutine { cont ->
            CognitoUser(email).confirmRegistration(code, false, { err, result ->
                if (err != null) {
                    cont.resumeWithException(CognitoException(err))
                } else if (result != null) {
                    cont.resumeWith(Result.success(result))
                }
            })
        }
    }

    private fun CognitoUser(username: String): CognitoUser {
        return CognitoUser(object : ICognitoUserData {
            override var Username: String = username
            override var Pool: CognitoUserPool = userPool
        })
    }

    suspend fun register(email: String, password: String): ISignUpResult {
        return suspendCoroutine { cont ->
            userPool.signUp(email, password, emptyArray(), emptyArray(), { err, result ->
                if (err != null) {
                    cont.resumeWithException(CognitoException(err))
                } else if (result != null) {
                    cont.resumeWith(Result.success(result))
                }
            })
        }
    }

    suspend fun login(username: String, password: String): CognitoUserSession {
        return suspendCoroutine { cont ->
            val cognitoUser = CognitoUser(username)

            cognitoUser.authenticateUser(AuthenticationDetails(object : IAuthenticationDetailsData {
                override var Username: String = username
                override var Password: String? = password
            }), object : IAuthenticationCallback {
                override var onSuccess: (session: CognitoUserSession, userConfirmationNecessary: Boolean) -> Unit =
                    { session, userConfirmationNecessary ->
                        cont.resumeWith(Result.success(session))
                    }
                override var onFailure: (err: Any) -> Unit = { err ->
                    cont.resumeWithException(CognitoException(err.unsafeCast<Error>()))
                }
            })
        }
    }

    suspend fun forgotPassword(email: String) {
        return suspendCoroutine { cont ->
            val cognitoUser = CognitoUser(email)

            cognitoUser.forgotPassword(object : `T$1` {
                override var onSuccess: (data: Any) -> Unit = { data ->
                    cont.resumeWith(Result.success(Unit))
                }
                override var onFailure: (err: Error) -> Unit = { err ->
                    cont.resumeWithException(CognitoException(err))
                }
            })
        }
    }

    /**
     * Reset the password after the user clicks an email link.
     */
    suspend fun resetPassword(email: String, code: String, newPassword: String) {
        return suspendCoroutine { cont ->
            val cognitoUser = CognitoUser(email)
            cognitoUser.confirmPassword(code, newPassword, object : `T$2` {
                override var onSuccess: (success: String) -> Unit = {
                    cont.resumeWith(Result.success(Unit))
                }
                override var onFailure: (err: Error) -> Unit = { err ->
                    cont.resumeWithException(CognitoException(err))
                }
            })
        }
    }

    /**
     * Clears all tokens.
     */
    fun logout() {
        val currentUser = userPool.getCurrentUser()
        if (currentUser != null) {
            currentUser.signOut {
                window.location.href = "/"
            }
        } else {
            window.location.href = "/"
        }
    }
}

@Serializable
public data class Token(
    val access_token: String,
    val refresh_token: String? = null,
    val id_token: String,
    val token_type: String,
    val expires_in: Int
)


@Serializable
public data class IdToken(
    val sub: String,

    @SerialName("cognito:groups")
    val cognitoGroups: List<String>,

    val email: String? = null
)

class CognitoException(err: Error) : Throwable(err.message, err) {
    val code: String? = err.asDynamic().code as? String
    val name: String? = err.asDynamic().name as? String
}